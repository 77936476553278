import Service from "../Service";
import ServiceReport from "../ServiceReport";

const resource = "ExtSupplier/";
const resourceReport = "orderpurchase/";

export default {
    byRuc(ruc) {
        return Service.get(resource + "by-ruc", {
            params: { ruc},
        });
    },

    pagination(request) {
        return Service.post(resource + "pagination", request, {
            params: {},
        });
    },

    getOrderById(id) {
        return Service.get(resource + "order-by-id", {
            params: { id},
        });
    },

    getVoucherByOrder(ordID) {
        return Service.get(resource + "voucher-by-order", {
            params: { ordID},
        });
    },

    getVoucherById(vouID) {
        return Service.get(resource + "voucher-by-id", {
            params: { vouID},
        });
    },

    printPDF(OrdID) {
        return ServiceReport.get(resourceReport + "pdforderpurcharse",  {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                OrdID: OrdID
            },
        });
    },



    update(request) {
        return Service.put(resource + "update", request, {
            params: {},
        });
    },

    saveBank(request) {
        return Service.post(resource + "save-bank", request, {
            params: {},
        });
    },

    deleteBank(request) {
        return Service.delete(resource + "delete-bank", {
            data: request
        });
    },

    
    savevoucher(files, ruc, xFlag) {
        return Service.post(resource + "save-voucher", files, {
            params: {ruc,xFlag},
        });
    },

    anulatedvoucher(request) {
        return Service.post(resource + "anulated-voucher", request, {
            params: {},
        });
    },

    updateFileVoucher(files, vodID, ruc) {
        return Service.post(resource + "update-file-voucher", files, {
            params: {vodID,ruc},
        });
    },

    addAttachVoucher(files, vouID, ruc) {
        return Service.post(resource + "add-attach-voucher", files, {
            params: {vouID,ruc},
        });
    },

};