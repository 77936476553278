<template>
<div>
    <v-footer style="border-radius: 5px; font-family:" dense flat class="body-2 font-weight-bold" :color="'white'">
        <p class="mb-0 mt-0" style="margin-top: 0px !important; font-size: 14px; font-weight: 600; color: #858585">
            {{ title }}
        </p>

        <v-spacer></v-spacer>
        <slot name="options"> </slot>

        <v-tooltip text="Nuevo" v-if="add">

            <template v-slot:activator="{ props }">
                <v-btn elevation="0" small v-bind="props" class="capitalize ml-1 mr-1" @click="$emit('add')">
                    <i style="font-size: 11px; margin-right: 5px; color: green" class="fa-solid fa-plus"></i>Nuevo
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Guardar" v-if="save">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" small color="success" v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('save')">
                    <i style="font-size: 11px; margin-right: 5px" class="fa-solid fa-save"></i>Guardar
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Editar" v-if="edit">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" outlined small color="info" v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('edit')">
                    <i style="font-size: 11px; margin-right: 5px; color: blue" class="fa-solid fa-pencil"></i>Editar
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Eliminar" v-if="remove">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" outlined small color="error" v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('remove')">
                    <i style="font-size: 11px; margin-right: 5px; color: red" class="fa-solid fa-trash"></i>Eliminar
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Visualizar" v-if="view">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" small v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('view')">
                    <i style="font-size: 11px; margin-right: 5px; color: maroon" class="fa-solid fa-eye"></i>Visualizar
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Salir" v-if="close">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" small v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('close')">
                    <i style="font-size: 11px; margin-right: 5px; color: black" class="fa-solid fa-close"></i>Salir
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Editar" v-if="excel">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" outlined small color="info" v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('excel')">
                    <!-- <i style="font-size: 11px; margin-right: 5px; color: green" class="far fa-file-excel"></i>Excel -->
                    <v-icon style="font-size:14px; margin-right:10px;" color="green darken-1">far fa-file-excel</v-icon>Reporte Excel
                </v-btn>
            </template>
        </v-tooltip>

    </v-footer>
</div>
</template>

<script>
export default {
    name: '',
    props: {
        title: {
            type: String,
            default: 'Vuetify Header'
        },

        add: {
            type: Boolean,
            default: false
        },
        save: {
            type: Boolean,
            default: false
        },
        edit: {
            type: Boolean,
            default: false
        },
        remove: {
            type: Boolean,
            default: false
        },
        view: {
            type: Boolean,
            default: false
        },
        close: {
            type: Boolean,
            default: false
        },
        excel: {
            type: Boolean,
            default: false
        }

    },
    data: () => ({}),
    mounted() {},
    methods: {},
    watch: {},
    computed: {},
    created() {}
};
</script>

<style lang="scss" scoped></style>
