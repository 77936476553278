<template>
<!-- <v-app class="scroll"> -->
<div id="app" class="app scroll portal-login">
    <router-view></router-view>
</div>
<!-- </v-app> -->
</template>

<script>

export default {
    components: {},
    name: "App",
    data() {
        return {

        }
    },

    created() {

    },
    computed: {
        isIdle() {
            return this.$store.state.idleVue.isIdle;
        },
    },

    watch: {},
    methods: {


    },

    mounted() {
        
    },
}
</script>

<style lang="scss">
@import "assets/base.scss";

</style>
<style lang="css">
@import "./styles/app.css";
</style>

