import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import _fun from '@/helpers/fun';
import _constapp from "@/helpers/const-es";


import { children as paymentgateway } from "@/router//paymentgateway.js";

Vue.use(VueRouter, {
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },

});
let children = [];
children.push(...paymentgateway);

const routes = [
    {
        path: '*',
        component: () => import('@/views/authentication/Error.vue')
    },
    /* {
        path: "*",
        redirect: "/login",
        
    }, */
    {
        path: "/",
        name: "HOME",
        component: Home,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },
    {
        path: "/micuenta",
        name: "MICUENTA",
        component: () =>
            import ("../views/Auth/Login.vue"),
    },
    {
        path: "/login",
        name: "LOGIN",
        component: () =>
            import ("../views/Auth/Login.vue"),
    },

    {
        path: "/crearcuenta",
        name: "CREATECUENTA",
        component: () =>
            import ("../views/Auth/RegisterBoxed.vue"),
    },

    {
        path: "/account/validate/",
        name: "VALIDATE_ACCOUNT",
        component: () =>
            import ("../views/Auth/AccountValidate.vue"),
    },

    {
        path: "/recuperarpassword",
        name: "RECOVERYPASSWORD",
        component: () =>
            import ("../views/Auth/RecoveryPassword.vue"),
    },

    
    {
        path: "/account/recovery/",
        name: "VALIDATE_PASSWORD_RECOVERY",
        component: () =>
            import ("../views/Auth/ChangePasswordValidate.vue"),
    },


    

];


const router = new VueRouter({
    mode: "history",
    routes,
});




router.beforeEach((to, from, next) => {

    let userMenu = JSON.parse(localStorage.getItem("UserOptions") || "{}");
    let activeSession = JSON.parse(localStorage.getItem("UsrID") || "0") > 0 ? true:false;

    var params = _fun.searchObject(userMenu == null ? [] : userMenu, to.name)
    let authenticated = to.matched.some((record) => record.meta.authenticated);
    let validOptions = to.matched.some((record) => record.meta.options);

    const token = localStorage.getItem("token");

    if (to.matched.some(record => record.meta.authenticated) ) {
        
        if (token == null) {
            next("/micuenta")
        } 
    } 

    if(to.name !== undefined){
        
        if(to.name !== "HOME" && to.name !== "MICUENTA" && to.name !== "LOGIN" ){
            
            if (params !== null) {
                to.params.security = params;
                if (authenticated && !activeSession) next("/micuenta");
                else if (!authenticated && activeSession) next("");
                else next();
            }else{
                to.matched.some((record) => {
                    if(!record.meta.authenticated && record.name !== "HOME"){
                        next();
                    }else{
                        if(record.name !== "HOME" && validOptions){
                            _fun.alertFull("No tiene permisos para esta opción.","warning")
                            next("/"); 
                        }else{
                            next(); 
                        }
                    }
                    
                })
            }
        } else if (authenticated && !activeSession){
           
            next("/micuenta");
        } 

        else if (!authenticated && activeSession) {
            next("/");
        } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {
            next("inicio");
        } else {  
            
            next();
        }

    }else{
        
        next()
    }
});

export default router;