<template>
<div class="app-page-title pt-2 pb-2" style="margin-bottom:0px;border-radius: 10px;font-family:Calibri">
        <div class="page-title-heading">
            <div class="page-title-icon" style="height:45px">
                <i :class="icon" />
            </div>
            <div>
                {{items.length > 0 ? items[items.length-1].text: ''}}
                <div class="page-title-heading">
                    <v-breadcrumbs :items="items" class="px-0 py-0">
                        <template v-slot:divider>
                            <i class="pe-7s-angle-right-circle" style="font-size:16px"> </i>
                        </template>
                    </v-breadcrumbs>
                </div>
            </div>
        </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/Layout/Breadcrumb.vue";

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faStar,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
    faStar,
    faPlus,
);

export default {
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        Breadcrumb
    },
    props: {
        icon: String,
        heading: String,
        subheading: String,

    },
    watch: {
        $route() {
            this.items = this.$route.meta.breadcrumb;
        },
    },
    mounted() {
        this.items = this.$route.meta.breadcrumb;
    },
    data() {
        return {
            items: [],
        };
    },

}
</script>
