<template>
<div style="padding-top: -2px; margin-top: -2px">
    <vc-select-generic style="margin-top: -1px; margin-left: -16px" itemtitle="dedDescription" itemvalue="dedValue" id="dedValue" :return-object="returnObject" :config="config" :value="valueLocal" @input="input" @change="change" :autofocus="autofocus" :outlined="outlined" :label="label" :clearable="clearable" :noDefault="clearable" :disabled="disabled" :readonly="readonly" :autocomplete="autocomplete" :full="full">
    </vc-select-generic>
</div>
</template>

<script>
import _sGenericService from "@/services/General/GenGenericService";

export default {
    name: "",
    components: {},
    props: {
        value: null,
        label: {
            type: String,
            default: "",
        },
        def: {
            type: Number,
            default: 0,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(value) {
            this.valueLocal = this.value;
        },
    },

    data() {
        return {
            valueLocal: null,
            config: {
                url: _sGenericService.getUrlDefinition(),
                title: this.label,
                params: {
                    defID: this.def,
                },
            },
        };
    },

    created() {
        this.valueLocal = this.value;
    },
    methods: {
        input(item) {
            this.$emit("input", item);
        },
        change(item) {
            this.$emit("change", item);
        },
    },
};
</script>
