let children = [];


children.push({
    path: "/proveedor/datospersonales",
    name: "SUP_PERSONALINFORMATION",
    component: () =>
        import ("../views/PersonalInformation/SupSupplier.vue"),
    meta: {
        authenticated: true,
        options:false
    },
});

children.push({
    path: "/proveedor/ordenes",
    name: "SUP_ORDERS",
    component: () =>
        import ("../views/Orders/SupOrders.vue"),
    meta: {
        authenticated: true,
        options:false
    },
});


children.push({
    path: "/inicio/",
    name: "INICIO",
    component: () =>
        import ("../views/Index/PagHome.vue"),
    meta: {
        authenticated: true,
        options:false
    },
});

children.push({
    path: "/preguntasfrecuentes",
    name: "SUP_QUESTION",
    component: () =>
        import ("../views/Question/SupQuestionFrecuent.vue"),
    meta: {
        authenticated: true,
        options:false
    },
});

children.push({
    path: "/proveedor/comprobantes",
    name: "BILL",
    component: () =>
        import ("../views/Bill/BillUpload.vue"),
    meta: {
        authenticated: true,
        options:false
    },
});


children.push({
    path: "/cuenta/cambiarpassword",
    name: "CHANGE_PASSWORD",
    component: () =>
        import ("../views/Auth/AccountSettings.vue"),
    meta: {
        authenticated: true,
        options:false
    },
});


export { children };