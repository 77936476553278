<template>
<div style="">
    <v-footer style="border-radius: 5px; font-family: Calibri" dense flat class="body-2 font-weight-bold" :color="color">
        <p class="mb-0 mt-0" style="margin-top: 0px !important; font-size: 14px; font-weight: 600; color: #858585">
            {{ title }}
        </p>

        <v-spacer></v-spacer>
        <slot name="options"> </slot>

        <v-tooltip bottom="" v-if="download">
            <template v-slot:activator="{ on }">
                <v-btn elevation="0" class="capitalize mr-1 ml-1" v-on="on" small @click="$emit('download')">
                    <v-icon style="font-size: 14px;margin-right:5px;">mdi-cloud-download</v-icon>Descargar
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Guardar" v-if="save">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" variant="tonal" small v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('save')">
                    <v-icon style="font-size: 16px; margin-right: 5px">mdi-content-save</v-icon> Guardar
                </v-btn>
            </template>
        </v-tooltip>

        <v-tooltip text="Salir" v-if="close">
            <template v-slot:activator="{ props }">
                <v-btn elevation="0" variant="tonal" size="small" v-bind="props" class="capitalize save ml-1 mr-1" @click="$emit('close')">
                    <i style="font-size: 11px; margin-right: 5px; color: black" class="fa-solid fa-close"></i>Salir
                </v-btn>
            </template>
        </v-tooltip>

    </v-footer>
</div>
</template>

<script>
export default {
    name: '',
    props: {
        title: {
            type: String,
            default: 'Vuetify Confirm Dialog Example'
        },

        close: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: '#f5f5f5'
        },

        //Eventos
        save: {
            type: Boolean,
            default: false
        },
        download: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {};
    },
    mounted() {},
    methods: {},
    watch: {},
    computed: {}
};
</script>

<style lang="scss" scoped></style>
