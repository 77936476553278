<template>
<div class="d-flex">

    

    <div class="header-btn-lg pr-0">
        <div class="widget-content p-0">
            <div class="widget-content-wrapper">               

                <div class="widget-content-left ml-3 header-user-info" style="margin-right:10px">
                    
                    <div class="widget-heading" >{{$fun.getUserFullName() ?? $fun.getUserName()}}</div>
                    <div class="widget-subheading">{{ $fun.getUserInfo()?.person.prsDocumentNumber ?? 'Sin Cargo'}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-btn-lg pr-0">
        <div class="widget-heading" ><a style="text-decoration: underline;" @click="$emit('logout')">Cerrar Sesión</a></div>
    </div>

</div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueCircle from "vue2-circle-progress";
import Trend from "vuetrend";

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faAngleDown,
    faCalendarAlt,
    faTrashAlt,
    faCheck,
    faFileAlt,
    faCloudDownloadAlt,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faEllipsisH,
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
    faAngleDown,
    faCalendarAlt,
    faTrashAlt,
    faCheck,
    faFileAlt,
    faCloudDownloadAlt,
    faFileExcel,
    faFilePdf,
    faFileArchive,
    faEllipsisH,
);

export default {

    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePerfectScrollbar,
        VueCircle,
        trend: Trend,

    },
    props: {
    },
    data: () => ({

        fill1: {
            gradient: ["#00b09b", "#96c93d"]
        },
        fill2: {
            gradient: ["#ff0844", "#ffb199"]
        },
        fill3: {
            gradient: ["#f6d365", "#fda085"]
        },
        showDrawerSection: false
    }),

    created() {
    },

    methods: {

    }
}
</script>
