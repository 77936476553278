<template>
<div class="app-header header-shadow" style="height:50px;">
    <!-- <div class="logo-src" /> -->
    <!-- <div class="logo-src" /> -->
    <!-- <v-img class="h-100 m-0 mt-0 " lazy-src="" max-height="75" max-width="160" src="./../../assets/images/logo-inverse.png"></v-img> -->

    <div class="app-header__content">
        <div class="app-header-left">
            <div class="ml-2 mt-2">
                <h6>{{$fun.getCompanyName()}}</h6>
            </div>

            <!-- <SearchBox /> -->
            <!-- <MegaMenu style="margin-top:10px" /> -->
        </div>
        <div class="app-header-right">
            <!--
             -->
            <HeaderDots style="margin-top:10px;display: flex;"  @logout="$emit('logout')" />
            <UserArea @logout="$emit('logout')"  />

        </div>

    </div>

    <div class="app-header__mobile-menu">
        <!-- <SearchBox /> -->
        <div>

            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" v-bind:class="{ 'is-active' : isOpen }" @click="toggleMobile('closed-sidebar-open')">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
        <div class="ml-2 mt-2" >
           <h6>{{$fun.getCompanyName()}}</h6>
        </div>

        <!-- <v-img class="h-100 m-0 mt-0 " lazy-src="" max-height="75" max-width="200" src="./../../assets/images/logo-inverse.png"></v-img> -->

    </div>
    <div class="app-header__menu">
        <span>
            <button class="btn-icon btn-icon-only" variant="primary" size="sm" v-bind:class="{ 'active' : isOpenMobileMenu }" @click="toggleMobile2('header-menu-open')">
                <div class="btn-icon-wrapper">
                    <font-awesome-icon icon="ellipsis-v" />
                </div>
            </button>
        </span>
    </div>

</div>
</template>

<script>
import SearchBox from './Header/SearchBox';
import UserArea from './Header/HeaderUserArea';
import HeaderDots from "./Header/HeaderDots"; 
/* 
import MegaMenu from "./Header/MegaMenu";
*/

import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faEllipsisV,
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(
    faEllipsisV,
);
export default {
    name: "Header",
    components: {
         SearchBox,
       /* 
        MegaMenu,
        */
        /* */
        HeaderDots, 
         UserArea,
        'font-awesome-icon': FontAwesomeIcon,
    },

    data() {
        return {
            isOpen: false,
            isOpenMobileMenu: false,
        }
    },
    props: {

    },

    created(){
    },
    methods: {
        toggleMobile(className) {
            const el = document.body;
            this.isOpen = !this.isOpen;

            if (this.isOpen) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },

        toggleMobile2(className) {
            const el = document.body;
            this.isOpenMobileMenu = !this.isOpenMobileMenu;

            if (this.isOpenMobileMenu) {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
    }
};
</script>
