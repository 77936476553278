import Service from "../Service";

const baseURL = Service.defaults.baseURL;
const resource = baseURL + "GenGenericType/";

export default {
    getUrlSearchArea() {
        return resource + "area-search";
    },

    getUrlSearchPosition() {
        return resource + "position-search";
    },

    getUrlDefinition() {
        return resource + "definition-search";
    },

    getUrlSearchWorker() {
        return resource + "worker-search";
    },

    exportExcel(request) {
        return Service.post(resource + "export-excel", request, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {}
        })
    },


};