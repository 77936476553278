import swal from "sweetalert2";
import router from "../router";
import moment from "moment";
import _fun from "@/helpers/fun";
import _constapp from "@/helpers/const-es";
import _sGeneric from "@/services/General/GenGenericService.js";

export default {
    /* **************************************************************************
     * Contiene las funciones javascript comunes para el proyecto,
     * todos las funciones empiezan con el prefijo "g"
     ****************************************************************************/
    alertFull(msg, type, subtitle) {
        swal.fire({
            title: msg,
            icon: type,
            allowOutsideClick: false,
        });
    },
    alert(msg, type, toa = true, position = "top") {
        type = type || "info";
        var timer = 2000;
        var ok = false;
        var cancel = false;
        switch (type) {
            case "error":
                var timer = 0;
                ok = true;
                break;
            case "warning":
                var timer = 3000;
                break;
            case "info":
                var timer = 10000;
                break;
            case "question":
                var timer = 0;
                ok = true;
                cancel = true;
                break;
        }
        var toast = null;
        toast = swal.mixin({
            toast: toa,
            position: position,
            showConfirmButton: ok,
            showCancelButton: cancel,
            cancelButtonText: "Cancelar",
            timer: timer,
            timerProgressBar: true,
            didOpen: function (toast) {
                toast.addEventListener("mouseenter", function () {
                    swal.stopTimer();
                });
                toast.addEventListener("mouseleave", function () {
                    swal.resumeTimer();
                });
            },
            customClass: {
                // Aplicamos la clase de estilo personalizado
                popup: "custom-sweetalert",
            },
        });

        return toast.fire({
            icon: type,
            title: msg,
        });
    },

    sweetAlert(msg, type) {
        if (_fun.getUserConfig() == null) {
            _fun.alert(
                "Error al cargar configuración inicial.",
                "error",
                false,
                "center"
            );
            window.location.reload();
        }
        return new Promise((resolve, reject) => {
            _fun.alert(
                msg,
                type,
                JSON.parse(_fun.getUserConfig().cfgAlertType),
                _fun.getUserConfig().cfgAlertAlign
            ).then(
                (val) => {
                    resolve(val);
                },
                (e) => {
                    reject(e);
                }
            );
        });
    },

    getUserID() {
        return localStorage.UsrID;
    },
    getWorkerID() {
        return localStorage.WrkID;
    },

    getCompanyID() {
        return localStorage.CpyID;
    },
    getCompanyName() {
        return localStorage.CpyName;
    },

    getUserName() {
        return localStorage.UsrName;
    },

    getUserFullName() {
        return localStorage.UsrFullName;
    },

    getAreName() {
        return localStorage.AreName;
    },

    getPositionName() {
        return localStorage.PstName;
    },

    getEmail() {
        return localStorage.WrkEmail;
    },

    getUserInfo() {
        return JSON.parse(localStorage.UserInfo);
    },

    getUserConfig() {
        return JSON.parse(localStorage.UserConfig);
    },

    getCompanyConfig() {
        return JSON.parse(localStorage.CompanyConfig);
    },

    searchObject(array, optCode) {
        for (var i = 0; i < array.length; i++) {
            var elemento = array[i];
            if (elemento.optCode === optCode) {
                return elemento.options;
            }

            if (Array.isArray(elemento.child)) {
                var encontrado = _fun.searchObject(elemento.child, optCode);
                if (encontrado) {
                    return encontrado;
                }
            }
        }

        return null;
    },

    isAdmin() {
        return router.history.current.params.security.isLevelAdmin;
    },

    getSecurity() {
        let objTmp = {
            isAllowAdd: false,
            isAllowDelete: false,
            isAllowEdit: false,
            isAllowExport: false,
            isAllowSave: false,
            isAllowView: false,
            isLevelAdmin: false,
            isLevelArea: false,
            isLevelAssistant: false,
            isLevelBoss: false,
            isLevelManager: false,
        };

        var options = router.history.current.params.security;

        if (options == undefined) {
            return objTmp;
        } else {
            return options;
        }
    },

    isEmptyVal: (val) => {
        var is = val === undefined || val === null || val === "";
        if (!is) is = val.length == 0;
        return is;
    },

    formatDateTimeText(value) {

        let val = _fun.formatDateTimeView(value);
        const fechaMoment = moment(val, 'DD/MM/YYYY HH:mm');

        // Obtiene el día de la semana en texto
        let  diaSemana = fechaMoment.format('dddd');
        diaSemana = diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1);
        // Obtiene el día del mes
        const diaMes = fechaMoment.format('DD');
        let mes = fechaMoment.format('MMMM');
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);
        // Obtiene la hora y los minutos
        const hora = fechaMoment.format('HH:mm');

        // Retorna la fecha y hora en el formato deseado
        return `${diaSemana} ${diaMes} de ${mes} a las ${hora}`;
 

        
    },

    formatDateTimeView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateTimeDB).format(_constapp.FormatDateTimeView);
        }
        return val;
    },


    formatDateTimeDB(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateTimeDB).format(
                _constapp.FormatDateTimeDB
            );
        }
        return val;
    },

    formatDateDB(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateDB).format(
                _constapp.FormatDateDB
            );
        }
        return val;
    },

    formatTimeDB(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatTimeDB).format(
                _constapp.FormatTimeDB
            );
        }
        return val;
    },

    getDate() {
        return moment().format(_constapp.FormatDateDB);
    },

    getHour() {
        return moment().format(_constapp.FormatTimeDB);
    },

    getDateDayMonth() {
        return moment().format(_constapp.FormatDateDayMonthDB);
    },

    getDayInitialMounth() {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );

        return firstDayOfMonth.toISOString().substr(0, 10); // Formato yyyy-mm-dd
    },

    fullScreen() {
        var el = document.documentElement;
        var rfs =
            el.requestFullScreen ||
            el.webkitRequestFullScreen ||
            el.mozRequestFullScreen ||
            el.msRequestFullScreen; // Para  Firefox  y chrome
        if (typeof rfs != "undefined" && rfs) {
            rfs.call(el);
        } else if (typeof window.ActiveXObject != "undefined") {
            //ParaInternet Explorer
            var wscript = new ActiveXObject("WScript.Shell");
            if (wscript != null) {
                wscript.SendKeys("{F11}");
            }
        }
    },

    downloadFile(file, type, filename) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename + "." + type.Extension); //or any other extension
        document.body.appendChild(link);
        link.click();
    },

    download(file, filename) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    },

    exportExcel(items) {
        if (items.length <= 0) {
            _fun.sweetAlert("No existen registros para exportar.", "warning");
            return;
        }
        let json = JSON.stringify(items);
        _sGeneric
            .exportExcel({
                jsonData: json,
            })
            .then((resp) => {
                _fun.downloadFile(resp.data, _constapp.TypeFile.EXCEL, "Data");
            });
    },

    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null
            ? ""
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    //Validar Archivos
    isValidExtension(items) {
        var isValid = false;

        for (let index = 0; index < items.length; index++) {
            if (
                items[index].rqdRequired == 1 &&
                items[index].rqdAttach == null
            ) {
                alert(items[index].rqdDescription + ". Obligatorio");
                items[index].rqdAttach = null;
                isValid = true;
            }

            if (
                items[index].rqdAttach !== null &&
                items[index].rqdAttach !== undefined
            ) {
                let archivo = items[index].rqdAttach.name;
                if (archivo !== null) {
                    var extension = archivo.substring(
                        archivo.lastIndexOf("."),
                        archivo.length
                    );
                    if (
                        items[index].rqdAccept.split(",").indexOf(extension) < 0
                    ) {
                        _fun.alert(
                            "[" +
                                items[index].rqdDescription +
                                "]. Archivo inválido. No se permite la extensión " +
                                extension,
                            "warning"
                        );
                        items[index].rqdAttach = null;
                        isValid = true;
                    }
                }
            }
        }
        return isValid;
    },

    isValidSize(items) {
        var isValid = false;
        for (let index = 0; index < items.length; index++) {
            if (
                items[index].rqdAttach !== null &&
                items[index].rqdAttach !== undefined
            ) {
                if (items[index].rqdAttach.size > items[index].rqdSize) {
                    _fun.alert(
                        "[" +
                            items[index].rqdDescription +
                            "]. El tamaño del archivo debe ser inferior a " +
                            items[index].rqdSize / 1048576 +
                            " MB!",
                        "warning"
                    );
                    isValid = true;
                }
            }
        }
        return isValid;
    },

    isValidLong(items) {
        var isValid = false;
        for (let index = 0; index < items.length; index++) {
            if (
                items[index].rqdAttach !== null &&
                items[index].rqdAttach !== undefined
            ) {
                if (
                    items[index].rqdAttach.name.length > items[index].rqdLength
                ) {
                    _fun.alert(
                        "[" +
                            items[index].rqdDescription +
                            "]. El tamaño del nombre de archivo debe ser inferior a " +
                            items[index].rqdLength +
                            " carácteres.",
                        "warning"
                    );
                    isValid = true;
                }
            }
        }
        return isValid;
    },

    uploadFiles(itemsAttach) {
        let files = [];

        for (let index = 0; index < itemsAttach.length; index++) {
            if (itemsAttach[index].rqdAttach !== null) {
                files.push({
                    rqdID: itemsAttach[index].rqdID,
                    rqdAttach: itemsAttach[index].rqdAttach
                })
            }
        }
        if (_fun.isValidExtension(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidSize(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidLong(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        var formData = new FormData();
        files.forEach((element) => {
            formData.append(element.rqdID, element.rqdAttach);
        });

        return formData;
    },
};
