<template>
<div>
    <v-label>
        <b>{{ label }}</b>
    </v-label>
    <v-select v-if="!autocomplete" :return-object="returnObject" hide-details :ref="'sgeneric' + label" placeholder="Seleccione opción" :loading="loading" :items="items" :item-text="itemtitle" :item-value="itemvalue" @input="input" @change="change" type="text" :clearable="clearable" :value="valueLocal" :outlined="outlined" :autofocus="autofocus" dense :disabled="disabled" :readonly="readonly">
    </v-select>
</div>
</template>

<script>
export default {
    name: "",
    components: {},
    props: {
        value: null,
        id: {
            type: String,
            default: "",
        },
        itemtitle: {
            type: String,
            default: "",
        },
        itemvalue: {
            type: String,
            default: "",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: "v-select",
        },
        config: {
            type: Object,
            default: null,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            items: [],
            loading: false,
            valueLocal: null,
        };
    },
    watch: {
        config: {
            handler() {
                this.valueLocal = 0;
                this.load();
            },
            deep: true,
        },
        valueLocal() {
            this.$emit("input", this.valueLocal);
        },
        value() {
            this.valueLocal = this.value;
            this.$emit("input", this.valueLocal);
        },
    },

    created() {
        this.$nextTick().then(() => {
            this.valueLocal = this.value;
            this.$emit("input", this.valueLocal);
        });
        this.load();
    },
    mounted() {},
    methods: {
        load() {
            if (this.config != null) {
                this.loading = true;
                this.$http
                    .get(
                        this.config.url,

                        {
                            params: this.config.params,
                        }
                    )
                    .then((r) => {
                        if (r.status == 200) {
                            this.items = r.data.data;
                            this.loading = false;
                        }
                    });
            }
        },

        input(value) {
            //  if(value) this.items=[];
            this.$emit("input", value);
        },
        change(value) {
            this.$emit("change", value);
        },
    },
};
</script>

<style></style>
