import Service from "./Service";
const resource = "Helper/";

export default {

    download(fileName, type = 1) {
        return Service.post(resource + "download-file", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                fileName: fileName,
                type: type
            },
        });
    },


    downloadByRute(fileName, rute) {
        return Service.post(resource + "download-file-rute", {}, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                fileName: fileName,
                rute: rute
            },
        });
    },
};