<template>
<div>

    <vc-header :title="'Nro. Comprobante: ' + voucher.vouSerieNumber" close @close="$emit('close')" />

    <v-card class="mt-0" style="max-height: 650px;  overflow-y: auto;">

        <v-card elevation="0" class="mt-0">
            <v-row style="margin:auto">
                <v-col cols="12" class="ml-5">
                    <h5 style="color:red"><b>Es importante tener en cuenta lo siguiente:</b></h5>
                    <ul class="ml-7">
                        <li>
                            <b>Cuando se emite una nota de crédito que anula un comprobante, es necesario registrar el nuevo documento, para ello se debe previamente anular el comprobante original.</b>
                        </li>
                        <li>
                            <b>En el caso de emitir una nota de crédito Tipo 13, es necesario adjuntarla en la sección "Otros anexos"</b>
                            <!-- <b>En caso se emita una N.C Tipo 13 se debe ADJUNTAR la N.C en la pestana [COMP. POR CORREGIR] - Sección "Otros anexos".</b> -->
                        </li>
                    </ul>
                    <v-divider></v-divider>
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="6">

                    <h5 class="ml-7"><b>Monto Orden:</b> {{
                            ordTotal >= 1000
                              ? ordTotal.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : ordTotal.toFixed(2)
                                }}</h5>
                </v-col>
                <v-col cols="6">
                    <h5><b>Monto Pendiente:</b> {{
                            totalPending >= 1000
                              ? totalPending.toLocaleString("es-MX", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : totalPending.toFixed(2)
                                }}</h5>
                </v-col>
            </v-row>

            <v-row style="margin:auto">
                <v-col cols="12" lg="12" md="12">

                    <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
                        <!-- {{voucherReceivedGroupObservation}} -->

                        <div class="card-body">
                            <h2 style="font-size: 18px;" class="card-title">
                                <h3>{{'Comprobante por corregir'}} | {{voucher.vouSerieNumber}}</h3>
                                <h5 style="color:#1f4d9d"></h5>
                            </h2>

                            <v-divider></v-divider>

                            <v-row>

                                <v-col cols="12">
                                    <ul>
                                        <v-card outlined elevation="3" class="ml-4 mb-3 pl-2 pr-2 pt-2 pb-2">
                                            <li class="ml-4"><a style="text-decoration:underline;color:blue"></a>
                                                <v-btn small color="error" text elevation="0" class="capitalize ml-4" outlined @click="anulated(voucher)">Anular Comprobante</v-btn>
                                            </li>

                                            <h6 class="mb-3 mt-3"> <b style="color:red">(*)</b><b> Una vez que haya realizado las correcciones sugeridas por el área de contabilidad, le pedimos que espere pacientemente mientras estas son validadas.</b></h6>
                                            <v-card elevation="1" class="mt-2 mb-2">

                                                <v-data-table dense disable-sort :items-per-page="-1" disable-pagination hide-default-footer :headers="headersObs" :items="voucher.detail.filter(x=>x.rqdActiveEditable == true)">

                                                    <template v-slot:item.edit="{ item }">
                                                        <v-icon @click="editAttach(item)" class="mr-1" style="font-size: 16px;color:green">mdi-file-edit-outline</v-icon>
                                                        <a style="text-decoration:underline;color:blue;font-size:9px" @click="editAttach(item)">Editar archivo</a>
                                                    </template>
                                                    <template v-slot:item.action="{ item }">

                                                        <v-icon style="font-size: 16px;color:green" @click="downloadFile(item)">mdi-download</v-icon>
                                                        <a @click="downloadFile(item)" style="text-decoration:underline;color:blue;font-size:9px">Descargar archivo</a>

                                                    </template>
                                                    <template v-slot:item.rqdActiveEditable="{ item }">
                                                        <v-chip v-if="item.rqdActiveEditable" x-small :color="'error'">{{'Por corregir'}}</v-chip>
                                                    </template>

                                                </v-data-table>
                                            </v-card>

                                            <v-card elevation="0" class="mt-5 mb-5" v-if="voucher.voudEnabledAttachOthers">
                                                <vc-header title="Subir Otros Anexos" />
                                                <h6 class="mt-1 ml-3">Se habilitó la opción para anexar otros archivos segun lo observado por el Área de Contabilidad.</h6>

                                                <v-file-input :rules="rules" show-size counter truncate-length="100" ref="myidattach" :accept="'.pdf,.xlsx,.txt,.docx,.zip,.jpg'" label="Otros anexos" v-model="othersAttach">
                                                </v-file-input>
                                                <v-btn title="Guardar" outlined elevation="2" style="text-transform:capitalize" small color="" @click="saveOthersAttach(voucher)">
                                                    <v-icon class="mr-2" style="font-size: 14px;color:green">fa-solid fa-upload</v-icon>Guardar
                                                </v-btn>

                                                <vc-header title="Anexos Cargados" class="mt-4" />

                                                <v-card elevation="1" class="mt-2 mb-2">

                                                    <v-data-table dense disable-sort :items-per-page="-1" disable-pagination hide-default-footer :headers="headersAttach" :items="voucher.detail.filter(x=>x.rqdID == 17)">
                                                        <template v-slot:item.rqdAttach="{ item }">
                                                            {{item.rqdAttach}}<br>
                                                            <v-icon style="font-size: 16px;color:green" @click="downloadFile(item)">mdi-download</v-icon>
                                                            <a @click="downloadFile(item)" style="text-decoration:underline;color:blue;font-size:9px">Descargar archivo</a>

                                                        </template>
                                                    </v-data-table>
                                                </v-card>

                                            </v-card>

                                            <v-card elevation="0" style="margin-top: 2px;border-radius:10px">
                                                <vc-header title="Comentarios" />
                                                <div class="p-3">
                                                    <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

                                                        <div class="vertical-timeline-item vertical-timeline-element" v-for="comm in voucher.comment.filter(x=>x.vocVisible)" :key="comm.vocID">
                                                            <div>
                                                                <span class="vertical-timeline-element-icon bounce-in"></span>
                                                                <div class="vertical-timeline-element-content bounce-in">
                                                                    <label class="">{{$fun.formatDateTimeDB(comm.vocDate)}} | {{comm.vocDescription}}</label>
                                                                    <span class="vertical-timeline-element-date"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-card>
                                    </ul>

                                </v-col>
                            </v-row>
                        </div>

                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-card>

    <sup-service-hour />
    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />
    <vc-modal-dialog :dialog="dialogAttach" close @close="dialogAttach=false" xs title="Corregir Archivo">
        <template v-slot="{}">
            Archivo: <label style="font-size:9px"><b>{{itemAttach.rqdAttach}}</b></label>
            <v-row style="margin:auto" class="mt-4">
                <h5>Seleccione nuevo archivo</h5>
                <v-col cols="12" v-for="(item, index) in supportAttach.length > 0 ? supportAttach.filter(x=>x.rqdID == itemAttach.rqdID) : []" :key="index" class="s-col-form">
                    <v-file-input persistent-hint style="margin-bottom:-20px" :show-size="false" :ref="'attach' + item.rqdID" :accept="item.rqdAccept" :label="item.rqdDescription" v-model="item.rqdAttach">
                        <template slot="append">
                            <label style="font-size:9px"><b>{{item.rqdSize / 1048576}}MB</b></label>

                            <v-btn title="Guardar" color="info" icon elevation="0" class="capitalize ml-1 mr-1" small @click="editFile(item)">
                                <v-icon style="font-size:30px;margin-right:5px;">mdi-content-save</v-icon>
                            </v-btn>
                        </template>
                    </v-file-input>
                </v-col>
            </v-row>
        </template>
    </vc-modal-dialog>
</div>
</template>

<script>
//Services
import _sSupplier from "@/services/Supplier/SupplierService";
import _sHelper from "@/services/HelperService.js";

import _validaERP from "@/helpers/validaERP";
import SupServiceHour from '../Orders/SupServiceHour.vue';
export default {
    components: {SupServiceHour},
    name: '',
    props: {
        value: 0
    },

    data() {
        return {
            //Loading
            processing: false,
            errors: null,

            voucher: {
                detail: []
            },

            headersObs: [{
                    text: "Editar",
                    value: "edit",
                    align: "center",
                    width: 120

                },
                {
                    text: "Acciones",
                    value: "action",
                    align: "center",
                    width: 120

                },
                {
                    text: "Descripcion",
                    value: "rqdDescription"

                },
                {
                    text: "Archivo",
                    value: "rqdAttach"

                },
            ],

            //Anexos
            othersAttach: null,
            rules: [
                (value) =>
                !value ||
                value.size < 6000000 ||
                "El tamaño del archivo debe ser inferior a 6 MB!",
            ],
            headersAttach: [{
                    text: "Descripcion",
                    value: "rqdDescription"

                },
                {
                    text: "Archivo",
                    value: "rqdAttach"

                },
            ],

            //Edit Attach
            dialogAttach: false,
            itemAttach: null,
            AttachEdit: null,

            //Order
            supportAttach: [],
            ordTotal: 0,
            vouTotal: 0,

            orderLocal: {
                ordDocumentNumber: ""
            },
        };
    },

    created() {
        this.getInitialize()
    },

    computed: {
        totalPending() {
            return this.ordTotal - this.vouTotal
        }
    },

    methods: {

        clear() {

        },
        getInitialize() {
            this.getOrder()
            this.getVoucher()
        },

        getOrder(ordID) {
            this.processing = true
            _sSupplier
                .getOrderById(ordID)
                .then((r) => {
                        this.processing = false
                        this.orderLocal = r.data.data
                        this.supportAttach = this.orderLocal.supportAttach;
                        this.ordTotal = this.orderLocal.ordTotal || 0
                        this.vouTotal = this.orderLocal.vouTotal || 0

                    },
                    (e) => {
                        this.errors = e.response
                        this.processing = false;
                    })

        },

        async getVoucher() {
            this.processing = true;
            console.log("valueeeeeeeee", this.value);
            _sSupplier.getVoucherById(this.value).then(resp => {
                    this.processing = false;
                    this.voucher = resp.data.data
                    this.getOrder(this.voucher.ordID)
                    console.log("voucher", this.voucher);
                },
                (e) => {
                    this.errors = e.response
                    this.processing = false;
                })

        },

        downloadFile(item) {
            if (item == null) return;
            _sHelper.downloadByRute(item.rqdAttach, item.rqdRoute).then((r) => {
                this.$fun.download(r.data, item.rqdAttach);
            });
        },

        editAttach(item) {
            if (item == null) return;
            this.itemAttach = item;

            this.dialogAttach = true

        },

        async editFile(item) {

            if (this.$fun.isValidExtension([item])) {
                return
            }

            if (this.$fun.isValidSize([item])) {
                return
            }

            if (this.$fun.isValidLong([item])) {
                return
            }

            var formData = await this.$fun.uploadFiles([item]);
            this.$fun.alert("¿Seguro de actualizar archivo?", "question", false, "center").then(val => {
                if (val.value) {
                    this.processing = true;
                    let request = {
                        ordID: item.ordID,
                        vouID: this.itemAttach.vouID,
                        cpyID: this.$fun.getCompanyID(),
                        usrCreateID: this.$fun.getUserID(),
                    }

                    formData.append('voucher', JSON.stringify(request));

                    _sSupplier.updateFileVoucher(formData, this.itemAttach.vodID, this.$fun.getUserInfo().person.prsDocumentNumber).then(resp => {
                            this.processing = false;
                            this.getInitialize()
                            this.dialogAttach = false

                        },
                        (e) => {
                            this.errors = e.response
                            this.processing = false;
                        })
                }
            })

        },

        async saveOthersAttach(item) {
            var array = []
            array.push({
                rqdID: 17,
                rqdDescription: "Otros anexos",
                rqdRequired: 1,
                rqdAttach: this.othersAttach,
                rqdSize: 12582912,
                rqdAccept: ".pdf,.xlsx,.txt,.docx,.zip",
            })

            if (this.$fun.isValidExtension(array)) {
                return
            }

            if (this.$fun.isValidSize(array)) {
                return
            }

            if (this.$fun.isValidLong(array)) {
                return
            }

            var formData = await this.$fun.uploadFiles(array);

            this.$fun.alert("¿Seguro de Grabar archivo para Comprobante Nro.: ?" + item.vouSerie + "-" + item.vouNumber + "?", "question", false, "center").then(val => {
                if (val.value) {
                    this.processing = true;
                    let request = {
                        ordID: item.ordID,
                        vouID: item.vouID,
                        cpyID: this.$fun.getCompanyID(),
                        usrCreateID: this.$fun.getUserID(),
                    }

                    formData.append('voucher', JSON.stringify(request));

                    _sSupplier.addAttachVoucher(formData, item.vouID, this.$fun.getUserInfo().person.prsDocumentNumber).then(resp => {
                            this.processing = false;
                            this.$fun.alert("Adjunto cargado correctamente.", "success", false, "center")
                            this.getInitialize()

                        },
                        (e) => {
                            this.errors = e.response
                            this.processing = false;
                        })
                }
            })
        },

        async anulated(item) {
            this.$fun.alert("¿Seguro de anular Comprobante Nro.: " + item.vouSerie + "-" + item.vouNumber + "? \nRecuerde que la operación no puede ser revertida.", "question", false, "center").then(val => {
                if (val.value) {
                    this.processing = true;
                    _sSupplier.anulatedvoucher({
                        vouID: item.vouID,
                        usrUpdateID: this.$fun.getUserID()
                    }).then(resp => {
                            this.processing = false;
                            this.$fun.alertFull("Comprobante Nro. " + item.vouSerie + "-" + item.vouNumber + ", fue anulado exitosamente.", "success")
                            this.$router.go(0)

                        },
                        (e) => {
                            this.errors = e.response
                            this.processing = false;
                        })
                }
            })
        },

    },
};
</script>

<style lang="scss" scoped>

</style>
