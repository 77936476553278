<!-- <template lang="scss">
<div>
    <vc-loading :processing="processing" :errors="errors" @close="processing = false" />
    <v-dialog v-if="showLocal" v-model="showLocal" :width="lg ? '1170' : md ? '940' : sm ? '728' : width" :persistent="persistent" :hide-overlay="hideOverlay">
        <v-card>
            <vc-header :title="title" style="box-shadow: -2px -15px 15px 11px"></vc-header>

            <v-row class="mb-2 pl-1 pr-1 pt-1" style="margin: auto; margin-top: 0px">
                <v-card outlined elevation="0" class="p-4 w-100" style="max-height: 450px; overflow-y: auto">
                    <slot :item="row"></slot>
                </v-card>
            </v-row>

            <vc-footer style="box-shadow: -2px 15px 15px 11px" v-if="noFooter" :save="save && $fun.getSecurity().isAllowSave" :close="close" @save="saveChange()" @close="$emit('close')"></vc-footer>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: "vcModalDialog",
    props: {
        title: {
            type: String,
            default: "Vuetify Confirm Dialog Example",
        },
        save: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Boolean,
            default: false,
        },
        persistent: {
            type: Boolean,
            default: JSON.parse(localStorage.getItem("persistent")) ? true : false,
        },
        dialog: {
            type: Boolean,
            default: false,
        },
        noFooter: {
            type: Boolean,
            default: true,
        },
        hideOverlay: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: {},
        },
        config: {
            type: Object,
            default: null,
        },

        //Tamaños
        xs: {
            type: Boolean,
            default: true,
        },
        sm: {
            type: Boolean,
            default: false,
        },
        md: {
            type: Boolean,
            default: false,
        },
        lg: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 500,
        },
    },

    data() {
        return {
            row: {},
            processing: false,
            errors: null,
            alertType: JSON.parse(localStorage.getItem("AlertType")),
        };
    },
    created() {},
    computed: {
        showLocal: {
            get: function () {
                this.processing = false;
                this.row = this.data;
                return this.dialog;
            },
            set: function (value) {
                this.row = {};
                this.processing = false;
                this.$emit("close");
            },
        },

        propID() {
            for (var prop in this.config.model)
                if (this.config.model[prop] == "ID") return prop;
            return null;
        },
    },
    methods: {
        saveExecute() {
            return new Promise((resolve, reject) => {
                let objsave = this.row;
                let obj = Object.assign({}, this.row);
                let objService = {};

                objsave.CpyID = localStorage.getItem("CpyID");

                if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                    //objsave.obj[this.propID] = 0
                    objsave.UsrCreateID = this.$fun.getUserID();
                    objService.service = this.config.service.save;
                } else {
                    objsave.UsrUpdateID = this.$fun.getUserID();
                    objService.service = this.config.service.update;
                }

                objService.service(objsave, this.$fun.getUserID()).then(
                    (response) => {
                        resolve(response);
                        if (response.status == 200) {
                            this.processing = false;
                            if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                                this.$fun.alert(
                                    this.$const.MSG_004,
                                    "success",
                                    this.alertType,
                                    localStorage.getItem("AlertAlign")
                                );
                            } else {
                                this.$fun.alert(
                                    this.$const.MSG_005,
                                    "success",
                                    this.alertType,
                                    localStorage.getItem("AlertAlign")
                                );
                            }

                            this.$emit("close");
                        }
                    },
                    (e) => {
                        this.errors = e.response;
                        this.processing = true;

                        reject(e);
                    }
                );
            });
        },

        saveChange() {
            this.row.save = () => {
                return new Promise((resolve, reject) => {
                    this.$fun
                        .alert(
                            this.$const.MSG_001,
                            "question",
                            this.alertType,
                            localStorage.getItem("AlertAlign")
                        )
                        .then((val) => {
                            if (val.value) {
                                this.processing = true;

                                this.saveExecute().then(
                                    (r) => {
                                        resolve(r);
                                    },
                                    (e) => {
                                        reject(e);
                                    }
                                );
                            }
                        });
                });
            };
            this.$emit("save", this.row);
        },
    },
    destroyed() {},
};
</script>

<style lang="scss" scoped></style> -->

<template>
    <div>
        <vc-loading :processing="processing" :errors="errors" @close="processing = false" />
        <v-dialog v-if="showLocal" v-model="showLocal" :width="lg ? '1170' : md ? '940' : sm ? '728' : width" :persistent="persistent" :hide-overlay="hideOverlay">
            <v-card elevation="2">
                <vc-header :title="title" style="box-shadow: -2px -15px 15px 11px"></vc-header>

                <!-- <v-card elevation="0" variant="outlined" style="max-height: 450px; overflow-y: auto">
                    <v-row style="margin: auto" class="pb-7 pr-3">
                        <v-col cols="12">
                            <slot :item="formData"></slot>
                        </v-col>
                    </v-row>
                </v-card> -->
                <!-- 
                <v-row class="mb-2 pl-1 pr-1 pt-1" style="margin:auto;margin-top:0px">
                <v-card outlined elevation="0" class="pb-4" style="max-height: 450px;  overflow-y: auto;">
                        <slot :item="formData"></slot>
                    </v-card>
                </v-row> -->

                <v-row class="mb-2 pl-1 pr-1 pt-1" style="margin: auto; margin-top: 0px">
                    <v-card outlined elevation="0" class="p-4 w-100" style="max-height: 450px; overflow-y: auto">
                        <slot :item="formData"></slot>
                    </v-card>
                </v-row>

                <vc-footer style="box-shadow: -2px 15px 15px 11px" :save="save && $fun.getSecurity().isAllowSave" :close="close" @close="
                        $emit('close', false);
                    " @save="saveChange()">
                    <template v-slot:options>
                        <slot name="options"> </slot>
                    </template>
                </vc-footer>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: '',
    components: {},
    props: {
        title: {
            type: String,
            default: 'Vuetify Confirm Dialog Example'
        },

        entity: {
            type: Object,
            default: null
            //required: true
        },
        config: {
            type: Object,
            default: null
            //required: true
        },

        dialog: {
            type: Boolean,
            default: false,
            required: true
        },

        save: {
            type: Boolean,
            default: false
        },

        close: {
            type: Boolean,
            default: false
        },

        //Dialog
        persistent: {
            type: Boolean,
            default: true
        },
        hideOverlay: {
            type: Boolean,
            default: false
        },

        //Tamaños
        xs: {
            type: Boolean,
            default: true
        },
        sm: {
            type: Boolean,
            default: false
        },
        md: {
            type: Boolean,
            default: false
        },
        lg: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 500
        },
        noCompany: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            formData: {},

            //Loading
            processing: false,
            errors: null
        };
    },
    created() {},
    computed: {
        showLocal: {
            get: function () {
                this.formData = this.entity;
                return this.dialog;
            },
            set: function (value) {
                
                //this.formData = {};
                //this.$emit('close', value);
            }
        },
        propID() {
            for (var prop in this.config.model) if (this.config.model[prop] == 'ID') return prop;
            return null;
        }
    },
    watch: {
        showLocal:{
            handler(){

                if(!this.showLocal){
                    this.formData = {}
                }
            },
            deep: true
        }
    },

    methods: {
        saveExecute() {
            return new Promise((resolve, reject) => {
                let objsave = this.formData;
                let obj = Object.assign({}, this.formData);
                let objService = {};

                if (!this.noCompany) {
                    objsave.cpyID = this.$fun.getCompanyID();
                }

                if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                    objsave.usrCreateID = this.$fun.getUserID();
                    objService.service = this.config.service.save;
                } else {
                    objsave.usrUpdateID = this.$fun.getUserID();
                    objService.service = this.config.service.update;
                }

                if (objService.service == null || objService.service == undefined) {
                    this.$fun.sweetAlert(this.$const.MSG_NOT_SERVICE, 'warning');
                    this.processing = false;
                    return;
                }

                objService.service(objsave).then(
                    (response) => {
                        resolve(response);
                        if (response.status == 200) {
                            this.processing = false;
                            if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                                this.$fun.sweetAlert(this.$const.MSG_SUCCESS_SAVE, 'success');
                            } else {
                                this.$fun.sweetAlert(this.$const.MSG_SUCCESS_UPDATE, 'success');
                            }

                            this.$emit('close');
                        }
                    },
                    (e) => {
                        this.errors = e.response;
                        this.processing = false;

                        reject(e);
                    }
                );
            });
        },

        saveChange() {
            this.formData.save = () => {
                return new Promise((resolve, reject) => {
                    var msg = '';
                    let obj = Object.assign({}, this.formData);
                    if (obj[this.propID] == 0 || obj[this.propID] == undefined) {
                        msg = this.$const.MSG_SAVE;
                    } else {
                        msg = this.$const.MSG_UPDATE + ' | ' + obj[this.propID];
                    }
                    this.$fun.sweetAlert(msg, 'question').then((val) => {
                        if (val.value) {
                            this.processing = true;

                            this.saveExecute().then(
                                (r) => {
                                    resolve(r);
                                },
                                (e) => {
                                    reject(e);
                                }
                            );
                        }
                    });
                });
            };
            this.$emit('save', this.formData);
        }
    },
    destroyed() {}
};
</script>
