<template>
<div class="header-dots">

    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right style="margin-top:-10px">
        <span slot="button-content" @click="byRuc">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-danger"></div>
                <i class="lnr-bullhorn text-danger" style="font-size: 23px;
    padding-left: 3px;"></i>
                <!-- <div class="badge badge-dot badge-dot-sm badge-danger">Notifications</div> -->
            </div>
        </span>
        <div class="dropdown-menu-header mb-0">

            <div class="dropdown-menu-header-inner bg-deep-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">Notificaciones</h5>
                    <h6 class="menu-header-subtitle">
                        
                       <!--  <b> {{supplier.notification.length}}</b>  -->
                    </h6>
                </div>
            </div>
        </div>
        <div class="nav-justified">
            <b-tabs class="card-header-tab-animation" card>
                <b-tab title="Comp. Observados" class="p-3">
                    <div class="" v-if="supplier.voucherObservation.length > 0">
                        <div class="scroll-area-md">
                            <!-- <VuePerfectScrollbar class="scrollbar-container" v-once> -->
                            <a style="text-decoration:underline;color:blue" class="mb-5" href="/proveedor/ordenes">Ir a mis ordenes</a>
                            <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                <div class="vertical-timeline-item vertical-timeline-element" style="margin-bottom: -5px;" v-for="(item, index) in supplier.voucherObservation" :key="index">
                                    <div>
                                        <span class="vertical-timeline-element-icon bounce-in">
                                            <i class="badge badge-dot badge-dot-xl badge-success"></i>
                                        </span>
                                        <div class="vertical-timeline-element-content bounce-in">
                                            <a @click="openVoucher(item)" style="text-decoration:underline;">
                                                <h4 class="timeline-title">Comprobante Observado, Nro de Doc.:<br> {{item.vouSerieNumber}} </h4>
                                                
                                            </a>
                                            Monto: {{item.vouTotal}}
                                            <p> Nro de Orden: {{item.ordDocumentNumber}}
                                                <!--  <a href="javascript:void(0);">{{item.vouTotal}}</a> -->
                                            </p>
                                            <span class="vertical-timeline-element-date"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- </VuePerfectScrollbar> -->
                        </div>
                    </div>
                     <div v-else>
                        <div class="no-results">
                            <div class="swal2-icon swal2-success swal2-animate-success-icon">
                                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                                </div>
                                <span class="swal2-success-line-tip"></span>
                                <span class="swal2-success-line-long"></span>
                                <div class="swal2-success-ring"></div>
                                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                            </div>
                            <!-- <div class="results-subtitle">All caught up!</div> -->
                            <div class="results-title mt-6">¡No se han encontrado comprobantes observados!</div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Comentarios" class="p-3">
                    <div class="">
                        <div class="scroll-area-md">
                            <!-- <VuePerfectScrollbar class="scrollbar-container" v-once> -->
                            <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                <div class="vertical-timeline-item vertical-timeline-element" style="margin-bottom: -5px;" v-for="(item, index) in supplier.notification" :key="index">
                                    <div>
                                        <span class="vertical-timeline-element-icon bounce-in">
                                            <i class="badge badge-dot badge-dot-xl badge-success"></i>
                                        </span>
                                        <div class="vertical-timeline-element-content bounce-in">
                                            <h4 class="timeline-title">{{item.vocDescription?.toUpperCase()}}</h4>
                                            <p> {{$fun.formatDateTimeText(item.vocDate)}}
                                                <!-- <a href="javascript:void(0);">12:00 PM</a> -->
                                            </p>
                                            <span class="vertical-timeline-element-date"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!-- </VuePerfectScrollbar> -->
                        </div>
                    </div>
                </b-tab>
                <!-- <b-tab title="Status" class="pt-3">
                    <div>
                        <div class="no-results">
                            <div class="swal2-icon swal2-success swal2-animate-success-icon">
                                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                                </div>
                                <span class="swal2-success-line-tip"></span>
                                <span class="swal2-success-line-long"></span>
                                <div class="swal2-success-ring"></div>
                                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="results-title mt-6">¡No hay errores del sistema!</div>
                        </div>
                    </div>
                </b-tab> -->

            </b-tabs>
        </div>
    </b-dropdown>

    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-end dropdown-menu-xl" variant="link" no-caret right style="margin-top:-10px">
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <v-avatar size="42">
                    <img v-if="$fun.getUserInfo().worker == null" src="@/assets/images/User.png" width="80" />
                    <img v-else :src="$fun.getUserInfo().worker.wrkUriPhoto" width="80" />
                </v-avatar>
            </div>

        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <v-avatar size="88">
                        <img v-if="$fun.getUserInfo().worker == null" src="@/assets/images/User.png" width="80" />
                        <img v-else :src="$fun.getUserInfo().worker.wrkUriPhoto" width="80" />
                    </v-avatar>
                    <h5 class="menu-header-title mt-3">{{$fun.getUserFullName() ?? $fun.getUserName()}}</h5>
                    <div class="widget-subheading">{{$fun.getUserInfo()?.person.prsDocumentNumber ?? 'Sin Correo' }}</div>
                </div>
            </div>
        </div>
        <div class="scroll-area-xs" style="height: 350px;">
            <ul class="nav flex-column">
                <li class="nav-item-header nav-item">Menú</li>

                <li class="nav-item">
                </li>
                <li class="nav-item">
                    <a style="color: #1976d2;text-decoration:underline" href="/proveedor/datospersonales" class="nav-link">Datos Personales</a>
                </li>
                <li class="nav-item">
                    <a style="color: #1976d2;text-decoration:underline" href="https://cotizaciones.imperu.com.pe" class="nav-link">Mis Cotizaciones</a>
                </li>
                <li class="nav-item">
                    <a style="color: #1976d2;text-decoration:underline" href="/proveedor/ordenes" class="nav-link">Mis Ordenes</a>
                </li>

                <li class="nav-item">
                    <a style="color: #1976d2;text-decoration:underline" href="/cuenta/cambiarpassword" class="nav-link">Cambiar Contraseña</a>
                </li>

                <li class="nav-item">
                </li>

            </ul>

        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>

        <div class="grid-menu grid-menu-2col overflow-hidden pl-3 pr-3">
            <v-btn small block elevation="0" @click="$emit('logout')">Cerrar sesión</v-btn>
        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>
    </b-dropdown>

    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <v-dialog v-if="showFile" v-model="showFile" persistent>
        <sup-voucher-observation @close="showFile=false" :value="vouID" />
    </v-dialog>
</div>
</template>

<script>
//Services
import _sSupplier from '@/services/Supplier/SupplierService';

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CountryFlag from "vue-country-flag";
//Components

import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowUp,
    faCog
} from "@fortawesome/free-solid-svg-icons";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import SupVoucherObservation from '@/views/Bill/SupVoucherObservation.vue';

library.add(faArrowUp, faCog);

export default {
    components: {
        VuePerfectScrollbar,
        CountryFlag,
        "font-awesome-icon": FontAwesomeIcon,
        SupVoucherObservation,
    },
    props: {

    },
    data() {
        return {

            fill1: {
                gradient: ["#00b09b", "#96c93d"]
            },
            fill2: {
                gradient: ["#ff0844", "#ffb199"]
            },
            fill3: {
                gradient: ["#f6d365", "#fda085"]
            },
            showDrawerSection: false,
            supplier: {
                notification: [],
                voucherObservation:[]
            },
            showFile: false,
            vouID: 0,

            //Loading
            processing: false,
            errors: null,
        }
    },
    created() {
        //this.byRuc()
    },
    computed: {

    },
    methods: {
        byRuc() {
            this.processing = true
            _sSupplier
                .byRuc(this.$fun.getUserInfo().person.prsDocumentNumber)
                .then(resp => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.supplier = resp.data.data

                            console.log(resp.data.data);
                        }
                    },
                    (e) => {

                        this.errors = e.response
                        this.processing = false;
                        this.$router.push("/inicio")
                    })
        },

        openVoucher(item) {
            if (item == null) return;
            this.vouID = item.vouID
            this.showFile = true
            console.log("openvoucher", item);
        }

    }
};
</script>
